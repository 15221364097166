import React from 'react'
import type { RedFlagModalState } from '~/client/components/red-flags/custom-red-flag-detail'
import { useCorpCryptId } from '~/client/lib/hooks'
import { isCorpRedFlagType, isRelationRedFlagType } from '~/common/red-flags'
import type { ZAugmentedRedFlag } from '~/common/schema/red-flag'
import { mkUrlWithSearchParams } from '~/common/util'

export const redFlagQueryParameter = 'redFlag'

export const useGetRedFlagPrimaryObjectUrl = (): ((
  redFlag: ZAugmentedRedFlag
) => string | undefined) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()

  const getRedFlagPrimaryObjectUrl = React.useCallback(
    (redFlag: ZAugmentedRedFlag) => {
      if (redFlag.type === 'CUSTOM') return
      if (isRelationRedFlagType(redFlag.type)) {
        return mkUrlWithSearchParams(mkCurrentCorpRoute('relation', redFlag.primaryCryptId.idStr), {
          tab: 'red-flags',
          [redFlagQueryParameter]: redFlag.cryptId.idStr,
        })
      }
      if (isCorpRedFlagType(redFlag.type)) {
        return mkUrlWithSearchParams(mkCurrentCorpRoute('organizational-info'), {
          tab: 'red-flags',
          [redFlagQueryParameter]: redFlag.cryptId.idStr,
        })
      }
      if (redFlag.type === 'CUSTOM_DOC') {
        return mkUrlWithSearchParams(mkCurrentCorpRoute('doc', redFlag.primaryCryptId.idStr), {
          tab: 'red-flags',
          [redFlagQueryParameter]: redFlag.cryptId.idStr,
        })
      }

      throw new Error(`Invalid red flag type: ${redFlag.type}`)
    },
    [mkCurrentCorpRoute]
  )

  return getRedFlagPrimaryObjectUrl
}

export interface BaseRedFlagProps {
  openCustomRedFlagModal: (state: Exclude<RedFlagModalState, { mode: 'closed' }>) => void
}
