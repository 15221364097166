/* eslint-disable filename-rules/match */
import '@mantine/charts/styles.css'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import '@mantine/notifications/styles.css'
import { useCorpCryptIdTag } from 'client/components/monitoring'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import type { WithLayoutMethods } from '~/client/components/layout'
import { Layout } from '~/client/components/layout'
import { AppMeta } from '~/client/components/meta'
import { useQueryParamErrorConsumer } from '~/client/components/monitoring'
import { AppProvider } from '~/client/components/provider'
import { ReactQueryDevtoolsToggle } from '~/client/components/react-query-devtool'
import { useRedFlagCompute } from '~/client/components/red-flags'
import { useCheckStorageAccess } from '~/client/components/util/check-storage-access'
import { useOfflineNotification } from '~/client/components/util/offline-notification'
import { NewAppVersionModal } from '~/client/components/util/poll'
import { Ribbon } from '~/client/components/util/ribbon'
import { useAnalyticsTrackUserAndPage, useAppCheck } from '~/client/lib/hooks'
import { useHandleOauthRedirect } from '~/client/lib/hooks/auth'
import { useInvalidateOnFocus } from '~/client/lib/hooks/invalidate-on-focus'
import { useInvestorPreviewQueryParams } from '~/client/lib/hooks/investor-preview'
import { theme } from '~/client/lib/theme'
import { trpc } from '~/client/lib/trpc'
import { zenvCommon } from '~/common/zenv-common'
import './global.css'

interface CustomAppProps extends AppProps {
  Component: WithLayoutMethods<AppProps['Component']>
}

const NextNProgress = dynamic(() => import('nextjs-progressbar'))

const HooksRequiringProvider: React.FC = () => {
  useHandleOauthRedirect()
  useRedFlagCompute()
  useQueryParamErrorConsumer()
  return null
}

const App = (props: CustomAppProps) => {
  const { Component, pageProps } = props
  const router = useRouter()
  useOfflineNotification()
  useAppCheck()
  useInvalidateOnFocus()
  useCheckStorageAccess()
  useCorpCryptIdTag()
  // remove html + css only loader
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loader = document.getElementById('initial-loader')
      if (loader) loader.remove()
    }
  }, [])

  useEffect(() => {
    if (zenvCommon.NEXT_PUBLIC_ENABLE_CY_NEXT_ROUTER) {
      window.cyNextRouter = router // this makes the router available to Cypress
    }
  }, [router])

  useAnalyticsTrackUserAndPage()
  useInvestorPreviewQueryParams()

  const layoutProps = Component.getLayoutProps?.()

  return (
    <>
      <AppMeta />
      <AppProvider>
        <Ribbon />
        <NewAppVersionModal />
        <HooksRequiringProvider />
        <NextNProgress
          color={theme.colors.blue[6]}
          options={{ showSpinner: false }}
          showOnShallow={false}
        />
        <Layout {...(layoutProps ?? {})}>
          <Component {...pageProps} />
        </Layout>
        <ReactQueryDevtoolsToggle />
      </AppProvider>
    </>
  )
}

export default trpc.withTRPC(App)
