import { useRouter } from 'next/router'
import { CorpLayout, type CorpPageProps } from '~/client/components/layout/corp'

export { AerialAppShell } from './aerial-app-shell'
export { BadgeHoverCard } from './badge'
export { CorpLayout } from './corp'
export { AppHeader, appHeaderHeight } from './header'

type LayoutProps = CorpPageProps

export type WithLayoutMethods<O> = O & {
  getLayoutProps?: () => LayoutProps
}

/**
 * This component takes the page component for a given page and renders it in
 * the right layout based on the current route. eg `CorpLayout` for
 * 'corp/[corpCryptIdStr]' pages.
 *
 * This should be put in _app.tsx so that the layout state is preserved across
 * pages with the same layout.
 * Hooks and components that should keep their state between pages with the same
 * layout should be placed inside the layout components used here
\*/
export const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const router = useRouter()
  const isCorpPage = router.route.startsWith('/corp/[corpCryptIdStr]')
  if (isCorpPage)
    return (
      // Use key to force re mount when corp changes
      <CorpLayout key={router.query.corpCryptIdStr?.toString() ?? ''} {...props}>
        {children}
      </CorpLayout>
    )
  return <>{children}</>
}
