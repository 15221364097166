import type { CryptId } from '@cryptid-module'
import { useQueryClient } from '@tanstack/react-query'
import pMap from 'p-map'
import React from 'react'
import { useMultiDocStore } from '~/client/components/multi-doc-drop/state'
import { S3_CONCURRENCY_LIMIT } from '~/client/lib/hooks/s3'
import { computeSHA256, invalidateQueries } from '~/client/lib/util'
import { GenericErrorHandler } from '~/common/error-handler'
import { extractDocusignIdFromPdf } from '~/common/extract-docusign-id'
import { checkPdfCorrupt } from '~/common/pdfjs'
import type {
  ZCreateDoc,
  ZDocCryptId,
  ZLinkOptions,
  ZManualDocUploadSource,
  ZUpdateDoc,
} from '~/common/schema'
import { retryFn } from '~/common/util'
import { hooks } from './dependency-injection/interface'

interface CreateDocInput {
  // We calculate the sha256 here
  docInfo: Omit<ZCreateDoc, 'sha256'>
  path: string
  docCryptId?: never
  file: File
  linkOptions?: ZLinkOptions
}

interface UpdateDocInput {
  docInfo: ZUpdateDoc
  path?: never
  docCryptId: CryptId
  file?: File
  linkOptions?: never
}

type UploadStep = 'checkPdfCorrupt' | 'generateThumb' | 'uploadThumb' | 'uploadFile' | 'createDoc'

class UploadStepError extends Error {
  constructor(
    public step: UploadStep,
    cause: unknown
  ) {
    super(`An error occurred while uploading a document on the ${step} step`, { cause })
  }
}

type UseCreateDocAndUploadInput = CreateDocInput | UpdateDocInput

const useUpdateUploadStateWrapper = ({
  onError,
  onIncrementStatus,
}: Pick<CreateDocAndUploadCallbacks, 'onError' | 'onIncrementStatus'>) => {
  return React.useCallback(
    async <TData>(fn: () => Promise<TData>, path: string, step: UploadStep) => {
      try {
        const result = await fn()
        onIncrementStatus?.(path)
        return result
      } catch (error) {
        if (error instanceof Error) {
          onError?.(path, error)
        }
        throw new UploadStepError(step, error)
      }
    },
    [onError, onIncrementStatus]
  )
}

interface UseCreateDocAndUploadSingle {
  /**
   * Create or updates doc and uploads file and thumbnail to S3 while
   * maintaining upload state
   * @param input
   * @returns
   */
  createDocAndUpload: (input: UseCreateDocAndUploadInput) => Promise<ZDocCryptId>
}
interface UseCreateDocAndUploadMulti {
  /**
   * Runs createDocAndUpload in parallel with pMap at a concurrency limit and
   * invalidates the trpc context after all uploads are completed.
   * @param inputs
   * @returns
   */
  createDocAndUploadBatch: (
    inputs: UseCreateDocAndUploadInput[]
  ) => Promise<{ successfulDocs: ZDocCryptId[]; failedSteps: Partial<Record<UploadStep, number>> }>
}

interface CreateDocAndUploadCallbacks {
  onFinish?: (sha256: string, cryptId: CryptId) => void
  onError?: (path: string, error: Error) => void
  onIncrementStatus?: (path: string) => void
}
const useCreateDocAndUploadRaw = (
  sourceType: ZManualDocUploadSource['type'],
  { onFinish, ...callbacks }: CreateDocAndUploadCallbacks
) => {
  const skipInvalidationMeta = {
    meta: {
      skipInvalidate: true,
    },
  } as const

  const docUploadUrls = hooks.trpc().doc.uploadFileUrls.useMutationWithCorp(skipInvalidationMeta)
  const docCreate = hooks.trpc().doc.create.useMutationWithCorp(skipInvalidationMeta)
  const docUpdate = hooks.trpc().doc.update.useMutationWithCorp(skipInvalidationMeta)

  const thumbUpload = hooks.useS3Upload({ meta: { noErrorNotification: true } })
  const fileUpload = hooks.useS3Upload({ retry: 0 })
  const generateThumbnail = hooks.useGenerateThumbnail()
  const updateUploadStateWrapper = useUpdateUploadStateWrapper(callbacks)

  return React.useCallback(
    async ({ docInfo, path, file, docCryptId, linkOptions }: UseCreateDocAndUploadInput) => {
      // update
      if (docCryptId) {
        return docUpdate.mutateAsync({
          doc: docInfo,
          cryptId: docCryptId,
        })
      }

      const buffer = await file.arrayBuffer()
      const pdfUnit8Array = new Uint8Array(buffer)

      // check if pdf is corrupted for consistency, as it is not too expensive
      // to do. It is somewhat redundant, as we already are notified of corrupt
      // documents when trying to generate the thumbnail.
      await updateUploadStateWrapper(
        async () => {
          const corruptResult = await checkPdfCorrupt({ data: pdfUnit8Array })
          if (corruptResult.isCorrupted) {
            throw new Error('PDF is corrupted', { cause: corruptResult.cause })
          }
        },
        path,
        'checkPdfCorrupt'
      )

      // Upload file and generate thumbnail
      // This is done before the doc is actually inserted into Mongo
      // to avoid situations where a doc doesn't have a PDF

      const { cryptId, ...urls } = await updateUploadStateWrapper(
        () =>
          retryFn(async () => {
            // Getting a new url on each retry because the previous url is
            // likely expired
            const uploadUrls = await docUploadUrls.mutateAsync()

            await fileUpload.mutateAsync({
              url: uploadUrls.file,
              file: pdfUnit8Array,
              contentType: 'application/pdf',
            })

            return uploadUrls
          }),
        path,
        'uploadFile'
      )

      const thumbnail = await updateUploadStateWrapper(
        () => generateThumbnail.mutateAsync({ buffer }),
        path,
        'generateThumb'
      )
      await updateUploadStateWrapper(
        async () => {
          try {
            await thumbUpload.mutateAsync({
              url: urls.thumb,
              file: new Uint8Array(thumbnail),
              contentType: 'image/png',
            })
          } catch (e) {
            GenericErrorHandler.createAndCapture(
              'Failed uploading thumbnail. Upload will proceed',
              { cause: e }
            )
          }
        },
        path,
        'uploadThumb'
      )

      const sha256 = await computeSHA256(file)
      const docusignEnvelopeIdPdf = extractDocusignIdFromPdf(buffer)

      const result = await updateUploadStateWrapper(
        () =>
          docCreate.mutateAsync({
            doc: { ...docInfo, sha256, source: { type: sourceType, docusignEnvelopeIdPdf } },
            linkOptions,
            cryptId,
          }),
        path,
        'createDoc'
      )
      onFinish?.(sha256, result.cryptId)
      return result
    },
    [
      updateUploadStateWrapper,
      docUploadUrls,
      docUpdate,
      docCreate,
      sourceType,
      onFinish,
      thumbUpload,
      fileUpload,
      generateThumbnail,
    ]
  )
}

export const useCreateDocAndUploadSingle = (): UseCreateDocAndUploadSingle => {
  const queryClient = useQueryClient()
  const createDocAndUploadRaw = useCreateDocAndUploadRaw('manual-single-upload', {})

  const createDocAndUpload = React.useCallback(
    async (input: UseCreateDocAndUploadInput) => {
      const result = await createDocAndUploadRaw(input)
      // Invalidate here since we skipped invalidations in upload
      await invalidateQueries(queryClient)
      return result
    },
    [createDocAndUploadRaw, queryClient]
  )

  return {
    createDocAndUpload,
  }
}

export const useCreateDocAndUploadMulti = (): UseCreateDocAndUploadMulti => {
  const queryClient = useQueryClient()
  const setDuplicatesForUploadedFile = useMultiDocStore(
    (state) => state.setDuplicatesForUploadedFile
  )
  const incrementDocUploadState = useMultiDocStore((state) => state.incrementDocUploadState)
  const setDocErrorStatus = useMultiDocStore((state) => state.setDocErrorStatus)

  const createDocAndUploadRaw = useCreateDocAndUploadRaw('manual-bulk-upload', {
    onFinish: setDuplicatesForUploadedFile,
    onError: (path, error) => setDocErrorStatus(path, error.message),
    onIncrementStatus: incrementDocUploadState,
  })

  const createDocAndUploadBatch = React.useCallback(
    async (inputs: UseCreateDocAndUploadInput[]) => {
      const failedSteps: Partial<Record<UploadStep, number>> = {}

      const result = await pMap(
        inputs,
        (input) =>
          createDocAndUploadRaw(input).catch((e) => {
            // Do not stop a batch upload if one of the files results in an error
            // but still report it to Sentry
            GenericErrorHandler.captureException({ error: e })
            if (e instanceof UploadStepError) {
              failedSteps[e.step] = (failedSteps[e.step] ?? 0) + 1
            }
            return null
          }),
        {
          concurrency: S3_CONCURRENCY_LIMIT,
        }
      )
      // Invalidate here since we skipped invalidations in upload
      await invalidateQueries(queryClient)
      return { successfulDocs: result.filter(Boolean), failedSteps }
    },
    [createDocAndUploadRaw, queryClient]
  )

  return {
    createDocAndUploadBatch,
  }
}
