import "../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@20.8.7_less@4.2.0_sass@1.63.3_sugarss@4.0.1_vtt3mf6sqi6who355fvwswsopi/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22client%2Flib%2Fcss-util.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WW3W6jMBCF7%2FMUo6xWaqU6AtKkiaOVVvsMe7fqxQBDcAM2Mk5CVfXdVxhITX5p92KlQExm5pxvJobk54ZeE405lfDysi%2BSUsDbCMD7bt8AjEZZJkrnvFlmaOjOu1%2BNAN5HANPp1USf8kPqfH41lbm5vjcM4H00aag9aBe%2BLQsx2qy12sqYRSpTmsO3ZJEsE1w5JTxVO9K3CoVMSQuzOuMVXPRKkstGl6pOjaZdyaMtUQVGwrxy8Orm7TCEEUryLgJeXgJhSUxIV6bvfyTmDxJzU1hMGdYYeem4zDr9%2BUBYf3Yed9bHnQ%2FEPZI7B2xTHKenzmMxEDm4gPzUR14MRA5uIwdHyMvOA%2F8RedlHxqFT9m5P2esjh1baFQtVxcoUY7UHbzI9yDG1NbXkR5iDB49FZQ%2B9DvHOe4D2NfGDe9ekbeftpH5RVPa4Xh99DvKjzvH9cotBUdnjOmL8J0aDLBYlhhnFz9azUEIa0ox2JE3JAbPMKaEvdkUXpnkM6M3uwSsq8IsKpkVVrx8uJgX2NCsqYLOzeY%2Bt2OFU5x1vxGENJGBiS98%2BW3eo7xjLURohqXngMiExMmJHbOlOed2UbXVZ17Xj7cUnncxvqgzTSpkHOA3%2Bwnhdg8l2jJ2iocoFTTkPKVGaWlppSBoO43Hd917EJuX1ZOurHPVaSJZRYjgwf958mpJYp4ZDdx2LsrC3YZipaLM6%2B0uzT4UhRzJUxqj8IOrCYWK6Bv4Pm1HFKdiLJUIpcrRPHok58e7%2Fy6oXircam53jTYKyHxOGmiCL1LZuzXc8Ns4XjWHJDIbNDdjsmh9jo7c0fr61ywotctSvbFFvsr9PiR0cbQkAAA%3D%3D%22%7D"
export var accordionRelationTableStickyCell = 'jjwpfs1';
export var boxShadowHover = 'jjwpfsb';
export var docDetailButtonClass = 'jjwpfsd';
export var hoverAppearDelay150msClasses = {hover:'jjwpfs5',appear:'jjwpfs6'};
export var hoverAppearDelay250msClasses = {hover:'jjwpfs7',appear:'jjwpfs8'};
export var hoverAppearInstantClasses = {hover:'jjwpfs3',appear:'jjwpfs4'};
export var integrationCardBoxShadowClass = 'jjwpfse';
export var metadataRowHoverClasses = {hover:'jjwpfs9',appear:'jjwpfsa'};
export var miniDocBoxShadowHover = 'jjwpfsc';
export var navLinkChildrenPseudoClass = 'jjwpfsh';
export var primaryColorTextOnSelectedTabClass = 'jjwpfsk';
export var relationRowColorInActiveClass = 'jjwpfsf';
export var relationRowCursorClass = 'jjwpfsg';
export var relationStickyCellRow = 'jjwpfs0';
export var relationTableStickyCell = 'jjwpfs2';
export var shakeOnceAnimationClass = 'jjwpfsj';