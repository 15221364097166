import type { MantineSize } from '@mantine/core'
import { ThemeIcon } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import React from 'react'
import { BadgeHoverCard } from '~/client/components/layout'
import { ActiveRedFlagIcon } from '~/common/icons'
import { investorRedFlagExplanation } from '~/common/red-flags'

export const InvestorRedFlagExplanationHoverCard: React.FC<{ badgeSize?: MantineSize }> = ({
  badgeSize,
}) => (
  <BadgeHoverCard
    noBadgeWrap
    color='urgent'
    icon={<ActiveRedFlagIcon size='lg' />}
    badge={
      <ThemeIcon color='urgent' size={badgeSize}>
        <IconInfoCircle size='100%' />
      </ThemeIcon>
    }
    hoverTitle='Aerial automatically uncovers red flags'
    hoverText={investorRedFlagExplanation}
  />
)
