import type { CryptId } from '@cryptid-module'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { BaseModal, BaseRelationSearchModalContent } from '~/client/components/modals'
import { zIndex } from '~/client/components/z-index'
import { hooks, usePagination } from '~/client/lib/hooks'
import { useSetLinkToRelation } from '~/client/lib/hooks/doc-links'
import { useRelationSearch } from '~/client/lib/hooks/search'
import type { ZRelationTypeValues } from '~/common/schema/relation'

const useRelationsAutofill = (relationTypes?: ZRelationTypeValues[]) => {
  const autofill = useDocDetailState((state) => state.autofill)
  const names = autofill?.parties.map((o) => o.name) ?? [] // Using names here because emails are optional in the schema
  const emails = autofill?.emails ?? []
  const enabled = !!relationTypes && (names.length > 0 || emails.length > 0)
  const byAutofillQueryParams = {
    types: relationTypes ?? [],
    names,
    emails,
  }
  const autofillQueryResult = hooks
    .trpc()
    .relations.byAutofill.useQueryWithCorp(byAutofillQueryParams, { enabled })
  const autofillIsFetching = enabled && autofillQueryResult.isFetching
  return { autofillQueryResult, autofillIsFetching }
}

interface RelationSearchWithAutofillModalProps {
  initialValues?: string[]
  allowedTypes: ZRelationTypeValues[]
  docCryptId: CryptId
  opened: boolean
  onClose: () => void
  allowCorp: boolean
}

const RelationSearchWithAutofillModalContent: React.FC<
  Omit<RelationSearchWithAutofillModalProps, 'opened' | 'onClose'>
> = ({ allowedTypes, docCryptId, allowCorp, initialValues = [] }) => {
  const { autofillQueryResult, autofillIsFetching } = useRelationsAutofill(allowedTypes)
  const pagination = usePagination()
  const { setQueryObj, queryObj, searchResults, searchParams } = useRelationSearch({
    queryObj: { queries: initialValues },
    enabled: !autofillIsFetching,
    autofillCryptIds: autofillQueryResult.data,
    ...pagination,
    emptyQueryBehavior: { type: 'filterTypes', allowedTypes },
  })
  const setLinkMutation = useSetLinkToRelation(searchParams, docCryptId)
  if (searchResults.data) pagination.setTotalItems(searchResults.data.count)

  return (
    <BaseRelationSearchModalContent
      allowedTypes={allowedTypes}
      queryObj={queryObj}
      isLinked={(relation) => relation.docCryptIds.some((cryptId) => cryptId.equals(docCryptId))}
      allowCorp={allowCorp}
      autofillCryptIds={autofillQueryResult.data ?? []}
      setQueryObj={setQueryObj}
      searchResults={searchResults}
      pagination={pagination}
      searchParams={searchParams}
      onSetLink={setLinkMutation.mutateAsync}
    />
  )
}

export const RelationSearchWithAutofillModal: React.FC<RelationSearchWithAutofillModalProps> = ({
  opened,
  onClose,
  ...props
}) => {
  return (
    <BaseModal opened={opened} onClose={onClose} title='Link Relations' zIndex={zIndex.modal}>
      {/* Needs to be a separate component inside BaseModal because Mantine takes care
      of resetting components' states when the modal is closed*/}
      <RelationSearchWithAutofillModalContent {...props} />
    </BaseModal>
  )
}
