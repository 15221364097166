import type { TextInputProps } from '@mantine/core'
import { Divider, Popover, Stack, Switch, TextInput, ThemeIcon } from '@mantine/core'
import { Calendar } from '@mantine/dates'
import type { GetInputProps } from '@mantine/form/lib/types'
import { useFocusReturn } from '@mantine/hooks'
import { IconCalendar, IconX } from '@tabler/icons-react'
import dayjs from 'dayjs'
import React from 'react'
import { zIndex } from '~/client/components/z-index'
import toUTCDate, { fromUTCDate } from '~/common/date-utc'

interface DateImprecisePickerProps extends TextInputProps {
  dateFormProps: ReturnType<GetInputProps<Date | null | undefined>>
  isMonthFormInputProps: ReturnType<GetInputProps<boolean>>
}

// Not using ZImpreciseDate.display because it shows full dates in MM/DD/YYYY format
const formatDate = (date: Date | null | undefined, isMonth: boolean) => {
  if (!date) return ''
  return dayjs(date)
    .locale('en-US')
    .format(isMonth ? 'MMMM, YYYY' : 'MMMM D, YYYY')
}

// Use the first day of the month for month dates
const mkMonthDate = (date: Date | null | undefined) =>
  date ? dayjs(date).set('date', 1).toDate() : null

export const DateImprecisePicker: React.FC<DateImprecisePickerProps> = ({
  dateFormProps,
  isMonthFormInputProps,
  ...props
}) => {
  // We save the date as UTC but Mantine components use the dates in local timezone
  const localDate = dateFormProps.value ? fromUTCDate(dateFormProps.value) : undefined
  const isMonth = isMonthFormInputProps.value

  const [text, setText] = React.useState(formatDate(dateFormProps.value, isMonth))
  const [opened, setOpened] = React.useState(false)
  const [freezeText, setFreezeText] = React.useState(false)

  const onChange = React.useCallback(
    (
      newLocalDate: Date | null | undefined,
      newIsMonth: boolean | null | undefined,
      { skipSetText }: { skipSetText?: boolean } = {}
    ) => {
      dateFormProps.onChange(toUTCDate(newLocalDate))
      isMonthFormInputProps.onChange(newIsMonth)

      setFreezeText(skipSetText ?? false)
    },
    [dateFormProps, isMonthFormInputProps]
  )

  const returnFocus = useFocusReturn({ opened, shouldReturnFocus: false })

  const clear = React.useCallback(() => {
    onChange(null, false)
    setOpened(false)
  }, [onChange])

  React.useEffect(() => {
    // This needs to be in a useEffect to account for updates
    // that happen outside the component (i.e. autofill)
    if (!freezeText) setText(formatDate(localDate, isMonth))
  }, [localDate, isMonth, freezeText])

  const switchRef = React.useRef(null)

  return (
    <Popover
      position='bottom-start'
      zIndex={zIndex.modal}
      opened={opened}
      onChange={setOpened}
      trapFocus={false}
    >
      <Popover.Target>
        <TextInput
          {...props}
          label='Date'
          leftSection={<IconCalendar size={16} />}
          rightSection={
            localDate && (
              <ThemeIcon style={{ cursor: 'pointer' }} onClick={() => clear()} color='gray'>
                <IconX size={16} />
              </ThemeIcon>
            )
          }
          autoComplete='off'
          onChange={(e) => {
            const newText = e.currentTarget.value
            setText(newText)
            setOpened(true)

            if (newText.trim() === '') {
              clear()
              return
            }

            const parsedDate = dayjs(newText, { locale: 'en-US' })
            if (!parsedDate.isValid()) return

            onChange(parsedDate.toDate(), isMonth, { skipSetText: true })
          }}
          value={text}
          onClick={() => setOpened(true)}
          onBlur={(event) => {
            const relatedElement = event.relatedTarget // Element that gained focus
            // close popover if the switch did not gain focus
            if (switchRef.current !== relatedElement) {
              setOpened(false)
            }

            // Fix the text on blur
            setFreezeText(false)
          }}
          onFocus={() => setOpened(true)}
        />
      </Popover.Target>
      {/* Prevent losing focus when clicking on the popover */}
      <Popover.Dropdown onMouseDown={(e) => e.preventDefault()} onBlur={() => setOpened(false)}>
        <Stack align='end'>
          {/* Using the same Calendar component for both date and month picker, just changing the level */}
          <Calendar
            __preventFocus
            getDayProps={(newDate) => ({
              selected: localDate && dayjs(newDate).isSame(localDate, 'date'),
              onClick: () => {
                if (isMonth) return
                onChange(newDate, false)
                setOpened(false)
              },
            })}
            getMonthControlProps={(newDate) => ({
              selected: localDate && dayjs(newDate).isSame(localDate, 'month'),
              onClick: () => {
                if (!isMonth) return
                onChange(mkMonthDate(newDate), true)
                setOpened(false)
              },
            })}
            firstDayOfWeek={0}
            locale='en-US'
            defaultDate={localDate}
            minLevel={isMonth ? 'year' : undefined}
            // The key is a hack to make the calendar go to the correct page when the user
            // changes the month/year in the TextInput.
            key={`${isMonth}${localDate?.toString()}`}
          />

          <Divider w='100%' />
          <Switch
            ref={switchRef}
            label='Select only month'
            labelPosition='left'
            {...isMonthFormInputProps}
            // Keep focus on the text input even when the switch is clicked
            // e.preventDefault() would prevent the input from being checked by the user
            onClick={() => returnFocus()}
            tabIndex={-1}
            checked={!!isMonth}
            onChange={(e) => onChange(mkMonthDate(localDate), e.currentTarget.checked)}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
