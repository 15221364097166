import { ActionIcon, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconSend } from '@tabler/icons-react'
import type { Message } from '~/client/components/chat/util'
import type { ZChatThreadInput, ZChatThreadOutput } from '~/common/schema'

interface MessageFormProps {
  threadId?: string
  isLoading: boolean
  appendMessages: (...messages: Message[]) => void
  submitMessage: (props: ZChatThreadInput) => Promise<ZChatThreadOutput>
}

export const MessageForm: React.FC<MessageFormProps> = ({
  threadId,
  isLoading,
  appendMessages,
  submitMessage,
}) => {
  const chatForm = useForm({ initialValues: { text: '' } })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      const formElement = event.currentTarget.form
      if (formElement) {
        formElement.requestSubmit()
      }
    }
  }

  return (
    <form
      onSubmit={chatForm.onSubmit(async (values) => {
        const text = values.text.trim()

        if (isLoading || text.length === 0) return
        appendMessages({ text, role: 'user' })
        chatForm.reset()
        const newMessages = await submitMessage({ query: text, threadId })
        const fileIdDocMap = new Map(
          newMessages.docs.map((doc) => [doc.vectorStoreFileId, doc] as const)
        )

        const processedMessages = newMessages.messages.map((message) => {
          let messageText = message.content.map((c) => c.text.value).join('\n')
          const annotations = message.content.flatMap((c) => c.text.annotations)
          const fileIds = annotations.map((annotation) => annotation.file_citation.file_id)
          const docs = fileIds.map((fileId) => fileIdDocMap.get(fileId)).filter(Boolean)

          annotations.forEach((annotation) => {
            messageText = messageText.replaceAll(
              annotation.text,
              // we use code snippets to display doc references. The message
              // component converts them into links that open the doc modal
              `\`${annotation.file_citation.file_id}\``
            )
          })

          return { role: message.role, text: messageText, docs, fileIdDocMap }
        })

        appendMessages(...processedMessages.reverse())
      })}
    >
      <Textarea
        {...chatForm.getInputProps('text')}
        autoFocus
        autosize
        placeholder='Enter message'
        m='sm'
        onKeyDown={handleKeyDown}
        maxRows={4}
        rightSection={
          <ActionIcon
            type='submit'
            disabled={chatForm.values.text.trim().length === 0 || isLoading}
          >
            <IconSend />
          </ActionIcon>
        }
        styles={{
          section: { display: 'flex', alignItems: 'end', margin: 3, width: 'fit-content' },
        }}
      />
    </form>
  )
}
