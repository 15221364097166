/* eslint-disable max-lines */
import type { CryptId } from '@cryptid-module'
// eslint-disable-next-line custom-rules/no-node-packages-in-client
import type { MailDataRequired } from '@sendgrid/mail'
import type {
  RequestInfo as NodeRequestInfo,
  RequestInit as NodeRequestInit,
  Response as NodeResponse,
} from 'node-fetch'
import { pick } from 'underscore'
import { z } from 'zod'
import type { TopPath } from '~/common/route/top-route'
import type { ZDocFilters } from '~/common/schema'
import type { ZRelationFilters } from '~/common/schema/relation'

type AttachmentData = Exclude<MailDataRequired['attachments'], undefined>[number]

export interface Attachment extends Omit<AttachmentData, 'contentId'> {
  /** Use this field to define the content id of the attachment. Sendgrid's
   * types are wrong, the code expects `content_id` while the type defines
   * `contentId`. See https://github.com/sendgrid/sendgrid-nodejs/pull/1364 */
  content_id?: string
}

export interface EmailAvatar {
  attachment: Attachment
  url: string
}

export const parseJsonBase64 = (str: string): object => {
  // Not using Buffer in the client to avoid a big polyfill
  // https://stackoverflow.com/a/42334410
  const credentialString =
    typeof window === 'undefined' ? Buffer.from(str, 'base64').toString('ascii') : window.atob(str)
  return JSON.parse(credentialString) as object
}

/** Appends a path to a URL. If the path is actually a query string or hash, it
 * concatenates them. */
export const appendPath = (url: string, path?: string): string => {
  if (!path) return url
  if (path.startsWith('?') || path.startsWith('#')) return `${url}${path}`
  return `${url.replace(/\/$/, '')}/${path.replace(/^\//, '')}`
}

/**
 * Get the route for the given path prefixed with the given corpId.
 * If the subPath is a query string or hash, it will be concatenated to the topPath.
 */
export const mkCorpRoute = (corpCryptId: CryptId, topPath: TopPath, subPath?: string): string => {
  return appendPath(`/corp/${corpCryptId.idStr}/${topPath}`, subPath)
}

export const getErrorMessage = (error: unknown, noJson?: boolean): string => {
  let message: string | undefined
  if (!error) message = 'Unknown Error'
  else if (typeof error === 'string') message = error
  else if (error instanceof Error) message = error.message
  else message = noJson ? 'Unknown Error' : JSON.stringify(error)
  return message
}

export const getErrorMessageOpt = (error: unknown, noJson?: boolean): string | undefined => {
  if (!error) return undefined
  return getErrorMessage(error, noJson)
}

/**
 * Finds a property in an object provided a stringified property accessor.
 * Property access equivalent to optional chaining so it is safe to call
 * even if preceding object property is undefined.
 * @param obj Object to find property in
 * @param propString Property accessor but stringified
 * @example getObjectPropertyByString(obj, 'startDate.value')
 */
export const getObjectPropertyByString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: { [x: string]: any },
  propString: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | undefined => {
  return propString
    .split('.')
    .reduce(
      (accObj, prop, idx, arr) => accObj[prop] ?? (idx === arr.length - 1 ? undefined : {}),
      obj
    )
}

export const atOrThrow = <T>(array: readonly T[], key: number): T => {
  const item = array.at(key)
  if (item === undefined) throw new Error(`Array ${array} does not contain an item at index ${key}`)
  return item
}

export const objGetOrThrow = <
  TRecord extends Record<string | number, unknown>,
  TAccess extends keyof TRecord,
>(
  object: TRecord,
  key: TAccess
): TRecord[TAccess] => {
  const item = object[key]
  if (item === undefined)
    throw new Error(`Object ${object} does not contain an item at index ${key.toString()}`)
  return item
}

export const mkUrlWithPrefillEmail = (base: string, email?: string): string => {
  if (!email) return base
  return `${base}?email=${encodeURIComponent(email)}`
}

export const mkUrlWithSearchParams = (
  url: string,
  searchParams: Record<string, string>
): string => {
  const params = new URLSearchParams(searchParams)
  const urlArray = url.split('?')
  return `${urlArray[0]}?${urlArray[1] ? `${urlArray[1]}&` : ''}${params.toString()}`
}

/**
 * Helper that works like Promise.all() but for all fields in an object. Shallow, does not recurse.
 * https://stackoverflow.com/a/64915904
 * @param obj
 */
export const promiseAllObjectValues = async <T extends object>(
  obj: T
): Promise<{ [K in keyof T]: Awaited<T[K]> }> => {
  return Promise.all(
    Object.entries(obj).map(async ([k, v]) => [k, await v])
    // eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries
  ).then(Object.fromEntries)
}

export const bufferToBase64 = (buffer: ArrayBuffer): string => {
  // Not using Buffer in the client to avoid a big polyfill
  if (typeof window === 'undefined') return Buffer.from(buffer).toString('base64')

  const bytes = new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
  return window.btoa(bytes)
}

/**
 * Uses the input type of the schema passed as the input type of the
 * preprocessor. Use this function when trying to preprocess data before parsing
 * without loosing the input type of the schema. An example is when trying to
 * migrate to a new database schema while supporting the old schema
 */
export const typeSafePreprocess = <I extends z.ZodTypeAny>(
  preprocessor: (arg: unknown) => unknown,
  schema: I
): z.ZodEffects<I, I['_output'], I['_input']> => {
  const resultSchema = z.preprocess(preprocessor, schema)

  return resultSchema
}

export const stripNonNumeric = (str: string | undefined): string =>
  str?.replace(/[^0-9,.-]/g, '') ?? ''

/**
 * Format prices.
 *
 * Returns null if the input is not a valid finite numeric.
 */
export const formatNumber = (
  value: number | undefined | null | string,
  precision?: number
): string | null => {
  if (value === null || value === undefined) return null
  const numberString = stripNonNumeric(value.toString())

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(Number(numberString))
}

export const delay = async (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

/** Utility that ensures the type passed is of type never. This is useful to ensure
 * exhaustiveness was checked. Use this on the `default` case of `switch`
 * statements as a workaround for the `array-callback-return` eslint rule, when
 * using them inside `.map()`.
 *
 * This is a known eslint issue https://github.com/typescript-eslint/typescript-eslint/issues/2841
 * */
export const exhaustivenessSafetyCheck = (never: never): never => {
  throw new Error(`Unknown value ${never}`)
}

// writing a simple version and not importing a bloated library
export const retryFn = async <T>(
  fn: () => Promise<T>,
  retry: number = 3,
  delayInMs: number = 200,
  exponentialBackOff: boolean = true
): Promise<T> => {
  try {
    return await fn()
  } catch (err) {
    if (retry > 0) {
      await delay(delayInMs)
      return retryFn(
        fn,
        retry - 1,
        exponentialBackOff ? delayInMs * 2 : delayInMs,
        exponentialBackOff
      )
    }
    throw err
  }
}

export class FetchError extends Error {
  readonly name = 'FetchError'
  constructor(
    public readonly status: number,
    public readonly statusText: string,
    public readonly cause: unknown
  ) {
    super(`Fetch request failed with status ${status}. Error: ${cause}`, { cause })
  }
}

/** Prevent us from checking if the response is `ok`, when it would throw otherwise */
export type OmitOkField<T> = Omit<T, 'ok'>

/**
 * Wrapper for `fetch`. that handles retries and throws an
 * error if there's a HTTP error in the response from `fetch`.
 *
 * Pass `nodeFetch` from `node-fetch`when using this function in nodejs
 */
export function fetchRetry(
  url: RequestInfo,
  props?: { init?: RequestInit; retry?: number; delayInMs?: number; exponentialBackOff?: boolean }
): Promise<OmitOkField<Response>>

export function fetchRetry(
  url: NodeRequestInfo,
  props: {
    init?: NodeRequestInit
    retry?: number
    delayInMs?: number
    exponentialBackOff?: boolean
    nodeFetch: (url: NodeRequestInfo, init?: NodeRequestInit) => Promise<NodeResponse>
  }
): Promise<OmitOkField<NodeResponse>>

/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions,func-style */
export async function fetchRetry(
  url: RequestInfo | NodeRequestInfo,
  {
    init,
    retry = 3,
    nodeFetch,
    delayInMs = 200,
    exponentialBackOff = true,
  }: {
    retry?: number
    delayInMs?: number
    exponentialBackOff?: boolean
    init?: RequestInit | NodeRequestInit
    nodeFetch?: (url: NodeRequestInfo, init?: NodeRequestInit) => Promise<NodeResponse>
  } = {}
): Promise<OmitOkField<Response | NodeResponse>> {
  const fn = async () => {
    const response = await (nodeFetch
      ? // eslint-disable-next-line custom-rules/no-direct-fetch-or-node-fetch
        nodeFetch(url as NodeRequestInfo, init as NodeRequestInit)
      : // eslint-disable-next-line custom-rules/no-direct-fetch-or-node-fetch
        fetch(url as RequestInfo, init as RequestInit))

    if (!response.ok) {
      const cause = await response.text()

      throw new FetchError(response.status, response.statusText, cause)
    }

    return response
  }

  return retryFn(fn, retry, delayInMs, exponentialBackOff)
}

const day = 1000 * 60 * 60 * 24

/**
 * Checks if a date (`date`) is within a number of days (`days`) from the current date.
 * @param date date to check
 * @param days number of days
 * @param today
 * @returns
 */
export const isWithinDays = (
  date: Date | string | number,
  days: number,
  today = new Date()
): boolean => {
  const targetDate = new Date(date)

  return Math.round(Math.abs(today.getTime() - targetDate.getTime()) / day) < days
}

/** Typesafe version of Object.keys()
 * Should ONLY be used when the object only has known keys at compile time.
 * For objects that can have keys added at runtime, Object.keys() should be
 * used, as the type information will be wrong.
 */
export const objectKeysTypesafe = <TObj extends Object>(obj: TObj): (keyof TObj)[] => {
  return Object.keys(obj) as unknown as (keyof TObj)[]
}

export type ObjectEntriesTypesafe<TObj extends Object> = {
  // Make sure all keys are present, but they have undefined as a possible value
  // if they are optional
  [K in keyof Required<TObj>]: [K, TObj[K]]
}[keyof TObj][]

/** Typesafe version of Object.entries()
 * Should ONLY be used when the object only has known keys at compile time.
 * For objects that can have keys added at runtime, Object.entries() should be
 * used, as the type information will be wrong.
 */
export const objectEntriesTypesafe = <TObj extends Object>(
  obj: TObj
): ObjectEntriesTypesafe<TObj> => Object.entries(obj) as ObjectEntriesTypesafe<TObj>

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AllObjectKeys<T, K extends keyof any = keyof T> = [K] extends [never]
  ? []
  : K extends K
    ? [K, ...AllObjectKeys<Omit<T, K>>]
    : []

const relationFilterKeys: AllObjectKeys<ZRelationFilters> = [
  'endDate',
  'party',
  'startDate',
  'type',
]

export const isRelationFilterEmpty = (filters: ZRelationFilters): boolean => {
  const pickFilters = pick(filters, relationFilterKeys)
  return objectEntriesTypesafe(pickFilters).every(([key, value]) => {
    switch (key) {
      case 'type':
        return (value ?? []).length === 0
      case 'party':
      case 'endDate':
      case 'startDate':
        return !value
      default:
        return exhaustivenessSafetyCheck(key)
    }
  })
}

const docFilterKeys: AllObjectKeys<ZDocFilters> = ['title', 'date', 'party', 'type']

export const isDocFilterEmpty = (filters: ZDocFilters): boolean => {
  const pickFilters = pick(filters, docFilterKeys)
  return objectEntriesTypesafe(pickFilters).every(([key, value]) => {
    switch (key) {
      case 'type':
        return (value ?? []).length === 0
      case 'party':
      case 'title':
      case 'date':
        return !value
      default:
        return exhaustivenessSafetyCheck(key)
    }
  })
}

export const dedupeDates = (dates: Date[]): Date[] => {
  // dedupe with Map instead of set because we need to preserve the objects
  const dedupeMap = new Map<string, Date>(dates.map((date) => [date.toISOString(), date]))

  return Array.from(dedupeMap.values())
}

// eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
type CommonKeys<T1, T2> = keyof T1 & keyof T2 // Keys common to both types
type UniqueKeys<T1, T2> = Exclude<keyof T1 | keyof T2, CommonKeys<T1, T2>> // Keys unique to one type

export type MergeTypes<T1, T2> =
  // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
  Pick<T1 & T2, CommonKeys<T1, T2>> & // Fields in both types
    Partial<Pick<T1 & T2, UniqueKeys<T1, T2>>> // Fields unique to one type are optional

export const getYearQuarter = (date: Date): { year: number; quarter: number } => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const quarter = Math.ceil(month / 3) // Map month to quarters
  return { year, quarter }
}
