import {
  AppShell,
  Button,
  Group,
  NavLink,
  type NavLinkProps,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import { IconUpload } from '@tabler/icons-react'
import { Router, useRouter } from 'next/router'
import React from 'react'
import { useLayoutStore } from '~/client/components/layout/state'
import { investorsUploadBlurb } from '~/client/components/multi-doc-drop'
import { useMultiDocModalStore } from '~/client/components/multi-doc-drop/state'
import { LabelWithIndex, NextLinkOpt } from '~/client/components/util'
import { zIndex } from '~/client/components/z-index'
import { navLinkChildrenPseudoClass } from '~/client/lib/css-util.css'
import { useCorpCryptId, useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { NavDropRouteData } from '~/common/route/top-route'
import { NavDropTopRouteShape } from '~/common/route/top-route'
import type { WithoutIndexesNames } from '~/common/route/without-index'
import { withoutIndexRoutes } from '~/common/route/without-index'
import { exhaustivenessSafetyCheck } from '~/common/util'
import { DocumentCountBadge, RedFlagBadge } from './badge'

const navSubLinkLeftMargin = 38

interface NavbarLinkProps extends Omit<NavLinkProps, 'leftSection'> {
  href?: string
  icon?: React.ReactNode
  onClick?: () => void
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ icon, href, disabled, ...props }) => {
  const router = useRouter()
  const paddingLeft = 12 // default mantine padding
  const iconSize = 26 // default size
  const marginForBorder = paddingLeft + iconSize / 2

  const active = router.asPath.split('?')[0] === href
  const navLink = (
    <NavLink
      pl={paddingLeft}
      leftSection={
        icon ? !disabled ? <ThemeIcon size={iconSize}>{icon}</ThemeIcon> : icon : undefined
      }
      component={NextLinkOpt}
      href={href}
      disabled={disabled || (!props.children && !href && !props.onClick)}
      childrenOffset={navSubLinkLeftMargin - marginForBorder}
      p='sm'
      classNames={{ children: navLinkChildrenPseudoClass }}
      td='none'
      // Strip query parameters from the current path
      active={active}
      {...props}
      styles={{
        label: {
          fontSize: theme.fontSizes.md,
          color: active ? undefined : theme.colors.gray[8],
        },
        children: {
          borderLeft: `solid ${theme.colors.gray[4]} 1px`,
          marginLeft: marginForBorder,
        },
        ...props.styles,
      }}
    />
  )

  return disabled ? (
    <Tooltip label='Coming soon!' withArrow w='100%'>
      {navLink}
    </Tooltip>
  ) : (
    navLink
  )
}

const RouteLink: React.FC<{
  icon?: React.ReactNode
  route: NavDropRouteData
  href: string | undefined
}> = ({ icon, route, href }) => {
  return (
    <NavbarLink
      label={<LabelWithIndex label={route.name} indexList={route.indexList} />}
      href={href}
      icon={icon}
      mr='lg'
    />
  )
}

const investorDisabledLabels: Readonly<WithoutIndexesNames[]> = [
  'Admin & Settings',
  'Red Flags',
] as const

const WithoutIndexSection: React.FC = () => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const { data: auth } = useCurrentCorpAuth()
  return (
    <>
      {withoutIndexRoutes.map((route) => {
        const disabledBecauseInvestor =
          auth?.level === 'investor' && investorDisabledLabels.includes(route.name)
        const disabledBecauseNonAdmin = auth?.level !== 'admin'

        const commonProps = {
          label: route.name,
          href: 'path' in route ? mkCurrentCorpRoute(route.path) : undefined,
          icon: <route.icon />,
          key: route.name,
        } as const
        switch (route.name) {
          case 'Dashboard':
            return <NavbarLink {...commonProps} mr='lg' />
          case 'Red Flags':
            return (
              <NavbarLink
                {...commonProps}
                icon={
                  <route.icon
                    {...(auth?.level === 'investor'
                      ? { color: 'gray', cursor: 'default' }
                      : { color: theme.colors.urgent[6] })}
                  />
                }
                href={disabledBecauseInvestor ? undefined : commonProps.href}
                rightSection={<RedFlagBadge />}
                styles={{ section: { pointerEvents: 'all' } }}
              />
            )
          case 'Admin & Settings':
            return (
              <NavbarLink {...commonProps} disabled={disabledBecauseInvestor}>
                {[...route.paths]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((path) => (
                    <NavbarLink
                      label={path.name}
                      href={mkCurrentCorpRoute(path.path)}
                      disabled={disabledBecauseNonAdmin || path.disabled}
                      key={path.name}
                    />
                  ))}
              </NavbarLink>
            )
          case 'Share Data Room':
            return <NavbarLink {...commonProps} disabled={auth?.level === 'investor'} />
          default:
            return exhaustivenessSafetyCheck(route)
        }
      })}
    </>
  )
}

export const AppNavbar: React.FC = () => {
  const openMultiDocUpload = useMultiDocModalStore((state) => state.openModal)
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const { data: auth } = useCurrentCorpAuth()
  const { isNavbarOpen, toggleNavbar } = useLayoutStore()
  React.useEffect(() => {
    const closeDashboardIfOpen = () => {
      if (isNavbarOpen) toggleNavbar()
    }
    Router.events.on('routeChangeComplete', closeDashboardIfOpen)
    return () => Router.events.off('routeChangeComplete', closeDashboardIfOpen)
  }, [isNavbarOpen, toggleNavbar])

  return (
    <AppShell.Navbar zIndex={zIndex.navbar}>
      {/**
       * Need to wrap Upload Documents button into Stack to prevent
       * the button height being resized when the viewport height is reduced.
       */}
      <Stack>
        <Tooltip
          multiline
          w={theme.other.widths.sm}
          label={investorsUploadBlurb}
          disabled={auth?.level !== 'investor'}
        >
          <Button
            size='md'
            m='12px'
            p='sm'
            onClick={() => openMultiDocUpload('doc-drop')}
            leftSection={<IconUpload />}
            data-testid='main-add-document-button'
            disabled={auth?.level === 'investor'}
          >
            Upload Documents
          </Button>
        </Tooltip>
      </Stack>

      <WithoutIndexSection />

      <Group px='sm' pt='lg' mb='xs' w='100%' align='center' justify='space-between' mih={45}>
        {/* lh=1 removes the extra space Mantine adds below the text element,
            making the text look better centered */}
        <Text tt='uppercase' fw={600} size='sm' c='primary' lh='1em'>
          Documents
        </Text>
        <DocumentCountBadge />
      </Group>
      <ScrollArea flex='auto'>
        <AppShell.Section>
          {NavDropTopRouteShape.map((topRoute) => (
            <NavbarLink
              icon={<topRoute.icon />}
              label={<LabelWithIndex label={topRoute.title} indexList={[topRoute.index]} />}
              data-testid={`navbar-${topRoute.title.toLowerCase().replaceAll(' ', '-')}`}
              disabled={topRoute.disabled}
              key={topRoute.title}
            >
              {topRoute.routes.map((route) => {
                // Processing Wizard is only available to super admins, and we
                // want to make it discoverable by replacing /processing
                if (route.collection === 'processing' && auth?.superAdmin)
                  return (
                    <RouteLink
                      route={route}
                      key={route.collection}
                      href={mkCurrentCorpRoute('processing-wizard')}
                    />
                  )

                return (
                  <RouteLink
                    route={route}
                    key={route.collection}
                    href={mkCurrentCorpRoute(route.collection)}
                  />
                )
              })}
            </NavbarLink>
          ))}
        </AppShell.Section>
      </ScrollArea>
    </AppShell.Navbar>
  )
}
