import React from 'react'
import type { ZAugmentedDoc } from '~/common/schema'

export interface Message {
  text: string
  role: 'user' | 'assistant'
  docs?: ZAugmentedDoc[]
}

interface Position {
  x: number
  y: number
}

interface UseChatScrollRtn {
  scrollAreaRef: React.RefObject<HTMLDivElement>
  showScrollToBottom: boolean
  scrollToBottom: () => void
  handleScrollPositionChange: (position: Position) => void
}

export const useChatScroll = (messageCount: number): UseChatScrollRtn => {
  const scrollAreaRef = React.useRef<HTMLDivElement>(null)
  const [isNearBottom, setIsNearBottom] = React.useState(true)

  const handleScrollPositionChange = (position: Position) => {
    const scrollArea = scrollAreaRef.current
    if (scrollArea) {
      setIsNearBottom(scrollArea.scrollHeight - scrollArea.clientHeight - position.y < 100)
    }
  }

  const scrollToBottom = () => {
    const scrollArea = scrollAreaRef.current
    if (scrollArea) {
      scrollArea.scrollTo({ top: scrollArea.scrollHeight, behavior: 'smooth' })
    }
  }

  React.useEffect(() => {
    // scroll to the bottom of the chat if the user gets a new message while
    // they are near the bottom
    if (isNearBottom) {
      scrollToBottom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageCount])

  return {
    scrollAreaRef,
    showScrollToBottom: !isNearBottom,
    scrollToBottom,
    handleScrollPositionChange,
  }
}
