import type { CryptId } from '@cryptid-module'
import type { UseQueryResult } from '@tanstack/react-query'
import type { inferProcedureInput } from '@trpc/server'
import React from 'react'
import type { UsePagination } from '~/client/lib/hooks'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { useDocSearchState } from '~/client/lib/hooks/search'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search/'
import type { SearchQueryObj } from '~/client/lib/query-params'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { Paginated } from '~/common/schema'
import type { ZAugmentedRelation, ZRelationTypeValues } from '~/common/schema/relation'
import type { AppRouter } from '~/common/trpc/app-router'
import { isRelationFilterEmpty } from '~/common/util'

interface UseRelationsSearchProps extends Pick<UsePagination, 'getQueryParams'> {
  enabled?: boolean
  queryObj?: SearchQueryObj
  autofillCryptIds?: CryptId[]
  emptyQueryBehavior:
    | { type: 'allowAllTypes' }
    | { type: 'filterTypes'; allowedTypes: ZRelationTypeValues[] }
}

export interface RelationsSearchParams
  extends Omit<inferProcedureInput<AppRouter['relations']['search']>, 'corpCryptId'> {}

interface UseRelationsSearch {
  debouncedQueryObj: SearchQueryObj
  queryObj: SearchQueryObj
  setQueryObj: (queryObj: React.SetStateAction<SearchQueryObj>) => void
  searchResults: UseQueryResult<Paginated<ZAugmentedRelation>>
  noQuery: boolean
  searchParams: RelationsSearchParams
}

export const useRelationSearch = ({
  enabled,
  queryObj = { queries: [] },
  autofillCryptIds = [],
  getQueryParams,
  emptyQueryBehavior,
}: UseRelationsSearchProps): UseRelationsSearch => {
  const { debouncedValue, setValue, value } = useDocSearchState(queryObj)

  const { texts, relationFilters } = splitTypeAndTextQueries(debouncedValue)
  const filters =
    emptyQueryBehavior.type === 'filterTypes' && !relationFilters.type?.length
      ? { ...relationFilters, type: emptyQueryBehavior.allowedTypes }
      : relationFilters

  const searchParams = {
    ...getQueryParams(),
    query: texts,
    autofillCryptIds,
    filters,
    getAllRelationsWhenNoTypes: emptyQueryBehavior.type === 'allowAllTypes',
  } as const

  const searchResults = hooks.trpc().relations.search.useQueryWithCorp(searchParams, {
    keepPreviousData: true,
    ...nextPageParamOpts(),
    enabled,
  })

  const trpcContext = hooks.trpc().useContext()
  const setQueryObj = React.useCallback(
    async (v: React.SetStateAction<SearchQueryObj>) => {
      await trpcContext.relations.search.cancel()
      setValue(v)
    },
    [setValue, trpcContext.relations.search]
  )

  const noQuery = isRelationFilterEmpty(relationFilters) && texts.length === 0

  return {
    queryObj: value,
    debouncedQueryObj: debouncedValue,
    setQueryObj,
    searchResults,
    noQuery,
    searchParams,
  }
}

export const mkRelationsAndCount = (
  searchResults: UseRelationsSearch['searchResults']
): { relations: ZAugmentedRelation[]; count: ZNumberExceed } => {
  const relations = searchResults.data?.data ?? []
  const count = searchResults.data?.count ?? { count: 0, exceeds: false }
  return { relations, count }
}
