/* eslint-disable max-lines */
import { ActionIcon, Box, Group, ScrollArea, Stack, Text, Title } from '@mantine/core'
import { IconCircleCaretDown } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import React from 'react'
import type { Message } from '~/client/components/chat/util'
import { useChatScroll } from '~/client/components/chat/util'
import { useDocDetailViewStore } from '~/client/components/doc-detail/state'
import { NextLinkOpt } from '~/client/components/util'
import { MiniDoc } from '~/client/components/util/doc'
import { theme } from '~/client/lib/theme'
import { dot, markdown } from './chat.css'

const ThinkingIndicator: React.FC = () => {
  return (
    <Group gap={4} align='end' pl='sm'>
      <Text fz='sm' c={theme.colors.gray[6]} style={{ fontStyle: 'italic' }}>
        thinking
      </Text>
      <Group gap={4} mb={7}>
        <Box className={dot} />
        <Box className={dot} />
        <Box className={dot} />
      </Group>
    </Group>
  )
}

const Markdown = dynamic(() => import('react-markdown'))

export const Messages: React.FC<{ isLoading: boolean; messages: Message[] }> = ({
  isLoading,
  messages,
}) => {
  const openDocDetail = useDocDetailViewStore((state) => state.openModal)
  const { handleScrollPositionChange, scrollAreaRef, scrollToBottom, showScrollToBottom } =
    useChatScroll(messages.length)

  return (
    <Stack pos='relative' h='100%'>
      {messages.length === 0 && !isLoading ? (
        <Title
          h='100%'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          order={4}
          c={theme.colors.gray[7]}
        >
          Hi, I&apos;m your AI Assistant.
          <br /> I can help you access and understand information about your company from your
          uploaded documents.
          <br /> How can I assist you today?
        </Title>
      ) : (
        <ScrollArea
          viewportRef={scrollAreaRef}
          onScrollPositionChange={handleScrollPositionChange}
          h='100%'
          offsetScrollbars
          styles={{ viewport: { paddingLeft: 12, paddingTop: 12 } }}
        >
          <Stack>
            {messages.map((message, index) => {
              const isUser = message.role === 'user'

              return (
                <Stack
                  gap='xl'
                  w='fit-content'
                  key={message.text + index}
                  bg={isUser ? theme.colors.primary[6] : theme.colors.gray[1]}
                  c={isUser ? 'white' : theme.colors.gray[8]}
                  px='sm'
                  py={5}
                  style={{
                    borderRadius: isUser ? '10px 0px 10px 10px' : '0px 10px 10px 10px',
                    alignSelf: isUser ? 'flex-end' : 'flex-start',
                    boxShadow: theme.shadows.xs,
                  }}
                >
                  <Markdown
                    className={markdown}
                    components={{
                      code: ({ children }) => {
                        const defaultJSX = <code>{children}</code>
                        if (typeof children !== 'string') return defaultJSX
                        // the markdown component interprets multiple code
                        // strings together as a single code string separated by "``"
                        const fileIds = children.split('``')

                        const components = fileIds.map((fileId, i) => {
                          const docIndex = message.docs?.findIndex(
                            (doc) => doc.vectorStoreFileId === fileId
                          )
                          if (docIndex === undefined || docIndex < 0) return defaultJSX
                          const doc = message.docs?.[docIndex]
                          if (!doc) return defaultJSX
                          return (
                            <NextLinkOpt
                              key={i}
                              onClick={() =>
                                openDocDetail({
                                  doc,
                                  allowedTypes: [doc.type],
                                })
                              }
                            >
                              [{docIndex + 1}]
                            </NextLinkOpt>
                          )
                        })

                        return <>{components}</>
                      },
                    }}
                  >
                    {message.text}
                  </Markdown>
                  {(message.docs?.length ?? 0) > 0 ? (
                    <Group gap='xs' mb='xs'>
                      {message.docs?.map((doc, i) => (
                        <MiniDoc
                          key={doc.cryptId.idStr}
                          isInactive={false}
                          doc={doc}
                          supportedTypes={[doc.type]}
                          leftSection={
                            <Text p={5} fz='xs'>
                              {i + 1}
                            </Text>
                          }
                        />
                      ))}
                    </Group>
                  ) : null}
                </Stack>
              )
            })}
            {isLoading ? <ThinkingIndicator /> : null}
          </Stack>
          <ActionIcon
            title='Scroll to bottom'
            pos='absolute'
            bottom={2}
            right={10}
            size='xl'
            c={theme.colors.primary[4]}
            style={{
              transition: 'opacity 0.5s ease, visibility 0.5s ease',
              opacity: showScrollToBottom ? 1 : 0,
              visibility: showScrollToBottom ? 'visible' : 'hidden',
            }}
            onClick={scrollToBottom}
          >
            <IconCircleCaretDown size={36} />
          </ActionIcon>
        </ScrollArea>
      )}
    </Stack>
  )
}
