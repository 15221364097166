import {
  Badge,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { AnimatedCountBadge } from '~/client/components/util/animated-count-badge'
import { LoadingErrorComp } from '~/client/components/util/error'
import { InvestorRedFlagExplanationHoverCard } from '~/client/components/util/red-flags'
import { zIndex } from '~/client/components/z-index'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { enhanceCount } from '~/common/enhance'
import { ActiveRedFlagIcon } from '~/common/icons'
import { redFlagExplanation } from '~/common/red-flags'

interface BadgeHoverCardProps {
  color: 'urgent' | 'primary' | 'go'
  icon: React.ReactNode
  badge: React.ReactNode
  noBadgeWrap?: boolean
  hoverTitle: string
  hoverText: React.ReactNode
}

export const BadgeHoverCard: React.FC<BadgeHoverCardProps> = ({
  color,
  icon,
  badge,
  noBadgeWrap,
  hoverText,
  hoverTitle,
}) => {
  const { other } = useMantineTheme()
  return (
    <HoverCard position='right-start' shadow='md' width={other.widths.sm} zIndex={zIndex.popover}>
      <HoverCard.Target>
        {noBadgeWrap ? (
          badge
        ) : (
          <Badge color={color} variant='filled'>
            {badge}
          </Badge>
        )}
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Group wrap='nowrap'>
          <ThemeIcon color={color} size='lg'>
            {icon}
          </ThemeIcon>
          <Stack gap='xs'>
            <Title order={3} c={color}>
              {hoverTitle}
            </Title>
            <Text c='dimmed' size='sm' component='div'>
              {hoverText}
            </Text>
          </Stack>
        </Group>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export const DocumentCountBadge: React.FC = () => {
  const docQueryResults = hooks
    .trpc()
    // staleTime and cacheTime to reduce additional calls to the server
    .docs.countAll.useQueryWithCorp(undefined, {
      staleTime: Infinity,
      cacheTime: Infinity,
    })

  return (
    <LoadingErrorComp queryResult={docQueryResults} loaderSize='xs' variant='tooltip'>
      {docQueryResults.data && (
        <AnimatedCountBadge
          value={docQueryResults.data}
          renderBadge={(docCount) => (
            <Badge color='primary' variant='filled'>
              {enhanceCount(docCount)}
            </Badge>
          )}
        />
      )}
    </LoadingErrorComp>
  )
}

export const RedFlagBadge: React.FC = () => {
  const currentCorpResults = useCurrentCorpAuth()
  const isInvestor = currentCorpResults.data?.level === 'investor'

  const queryResults = hooks
    .trpc()
    // staleTime and cacheTime to reduce additional calls to the server
    .redFlags.get.all.useQueryWithCorp(
      { dismissed: false, limit: 1 },
      {
        staleTime: Infinity,
        cacheTime: Infinity,

        enabled: !!currentCorpResults.data && !isInvestor,
      }
    )

  const totalActiveRedFlags = queryResults.data?.count ?? { count: 0, exceeds: false }

  return (
    <LoadingErrorComp
      queryResult={{
        ...queryResults,
        isLoading: isInvestor ? false : queryResults.isLoading || currentCorpResults.isLoading,
      }}
      loaderSize='xs'
      variant='tooltip'
    >
      {isInvestor ? (
        <InvestorRedFlagExplanationHoverCard />
      ) : totalActiveRedFlags.count === 0 ? (
        <BadgeHoverCard
          color='go'
          icon={<IconCircleCheck size='lg' />}
          badge={
            <ThemeIcon color='go'>
              <IconCircleCheck />
            </ThemeIcon>
          }
          noBadgeWrap
          hoverTitle='Aerial found no Red Flags!'
          hoverText={redFlagExplanation}
        />
      ) : (
        <AnimatedCountBadge
          value={totalActiveRedFlags}
          renderBadge={({ count }) => (
            <BadgeHoverCard
              color='urgent'
              icon={<ActiveRedFlagIcon size='lg' />}
              badge={count}
              hoverTitle={`Aerial found ${count} Red ${pluralize('Flags', count)}`}
              hoverText={redFlagExplanation}
            />
          )}
        />
      )}
    </LoadingErrorComp>
  )
}
