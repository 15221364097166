import { z } from 'zod'

const ZAnnotation = z.object({
  end_index: z.number(),
  file_citation: z.object({ file_id: z.string() }),
  start_index: z.number(),
  text: z.string(),
  type: z.literal('file_citation'),
})

const ZText = z.object({ annotations: ZAnnotation.array(), value: z.string() })

const ZMessageContent = z.object({ text: ZText, type: z.literal('text') })

export const ZMessage = z.object({
  role: z.enum(['user', 'assistant']),
  content: ZMessageContent.array(),
})

export interface ZMessage extends z.infer<typeof ZMessage> {}
