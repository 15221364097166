import { corpRedFlagRules } from './corp'
import { relationRedFlagRules } from './relation'

export * from './utils'
export { type CorpRedFlagInstance, corpRedFlagTypes } from './corp'
export {
  type RelationRedFlagInstance,
  getRelationDocRules,
  relationRedFlagRuleMap,
  relationRedFlagRuleTypeMap,
  personnelRedFlagTypes,
  equityRedFlagTypes,
} from './relation'

export { corpRedFlagRules, relationRedFlagRules }
export const allRedFlagRules = [...relationRedFlagRules, ...corpRedFlagRules]
export const redFlagTypes = allRedFlagRules.map((rule) => rule.type)
export type RedFlagType = (typeof redFlagTypes)[number]

export const typeRedFlagRuleMap = new Map(allRedFlagRules.map((rule) => [rule.type, rule]))

export const redFlagExplanation = `
Red Flags are issues with your legal documents that may jeopardize fundraising or acquisition.
Some Red Flags may be time-sensitive. You should quickly resolve the issue or dismiss it.
Speak to your lawyer if you have any questions or concerns about the issues.
`
export const investorRedFlagExplanation = `
Aerial’s Red Flag feature identifies potential issues in real time ensuring companies are up to date. With Investor permissions you currently do not have access to real time Red Flags.`
