import type { WithTime } from '@tianhuil/ts-mongo'
import { type WithId } from 'mongodb'
import { z } from 'zod'
import { AugmentedDocImpreciseDate, AugmentedDocString } from '~/common/schema/doc.util'
import { ZDateFilter, ZStringFilter } from '~/common/schema/filters'
import { ZMessage } from '~/common/schema/openai'
import { ZParty } from '~/common/schema/party'
import { docTypeMap } from '~/common/schema/type-map'
import { ZCryptId } from '~/common/schema/util'
import { withDisplaySchema } from '~/common/schema/with-display-schema'

namespace helpers {
  export const mapToEnum = <K extends string>(map: Record<K, unknown>): z.ZodEnum<[K, ...K[]]> =>
    z.enum<K, [K, ...K[]]>(Object.keys(map) as [K, ...K[]])
}

const types = helpers.mapToEnum(docTypeMap).options
const Type: (typeof types)[number] | undefined = undefined

export const ZDoc = z.object({
  title: withDisplaySchema(AugmentedDocString.optional(), 'docString', 'Title'),
  /** To prevent production from breaking when there are new types in the DB, we
   * show the type as MISCELLANEOUS if string is not part of the schema.
   * This has the drawback of saving the type as
   * MISCELLANEOUS if the document is edited and saved again.
   * This is not a big issue, since MISCELLANEOUS applies to all docs, and it's
   * unlikely that a user will edit these new docs in processing (and not in canary)
   *
   * Due to this change z.input<> and z.output<> types are different from each
   * other, since inputs will parse any string, but it outputs only values from
   * the enum.
   */
  type: z
    .string()
    .transform((v) => (types.includes(v) ? v : 'MISCELLANEOUS'))
    .pipe(helpers.mapToEnum(docTypeMap)),
  party: withDisplaySchema(ZParty.optional(), 'party', 'Counterparty'),
  sha256: z.string(),
  vectorStoreFileId: z.string().optional(),
  createdBy: z.string().email().optional(),
  dateImprecise: withDisplaySchema(
    AugmentedDocImpreciseDate.optional(),
    'docImpreciseDate',
    'Date'
  ),
})
export type ZDoc = z.infer<typeof ZDoc>

export const ZAugmentedDoc = Object.assign(
  ZDoc.extend({
    corpCryptId: ZCryptId,
    cryptId: ZCryptId,
    thumb: z.string(),
    url: z.string(),
    createdAt: z.date(),
    // Add augmented source when we begin using it on the client
    // We don't do it now since we want to omit this field for now
    // source: ZAugmentedSource,
  }),
  {
    types,
    Type,
    isType: (s: string): s is ZDocType => types.includes(s),
  }
)

export type ZAugmentedDoc<TDoc extends ZDoc = ZDoc> = TDoc & z.infer<typeof ZAugmentedDoc>

export const ZAugmentedDocWithIndexNumber = ZAugmentedDoc.extend({
  indexNumber: z.number().int(),
})
export type ZAugmentedDocWithIndexNumber = z.infer<typeof ZAugmentedDocWithIndexNumber>

// picks the most useful fields from ZAugmentedDoc for endpoints that are not
// required to return the entire ZAugmentedDoc but just the important fields
export const ZMinimalDoc = ZAugmentedDoc.pick({
  title: true,
  type: true,
  url: true,
  cryptId: true,
  corpCryptId: true,
  dateImprecise: true,
  createdAt: true,
})

export interface ZMinimalDoc extends z.infer<typeof ZMinimalDoc> {}

export const ZDocType = z.enum(types)
export type ZDocType = (typeof types)[number]

export type WithIdTime<T> = WithId<WithTime<T>>

export const ZManualDocUploadSource = z.object({
  type: z.enum(['manual-single-upload', 'manual-bulk-upload']),
  docusignEnvelopeIdPdf: z.string().optional(),
})

export type ZManualDocUploadSource = z.infer<typeof ZManualDocUploadSource>

// schemas for updating and creating documents
export const ZCreateDoc = ZDoc.omit({ createdBy: true }).extend({
  corpCryptId: ZCryptId,
  source: ZManualDocUploadSource.optional(),
})
export interface ZCreateDoc extends z.infer<typeof ZCreateDoc> {}

export const ZUpdateDoc = ZCreateDoc.omit({ sha256: true, createdBy: true })
  .partial()
  .merge(ZDoc.pick({ type: true }))
export interface ZUpdateDoc extends z.infer<typeof ZUpdateDoc> {}

export const ZLinkOptions = z.union([
  z.object({ type: z.literal('corp') }),
  z.object({ type: z.literal('relation'), cryptId: ZCryptId }),
])
export type ZLinkOptions = z.infer<typeof ZLinkOptions>

export const ZMissingDoc = Object.assign(
  z.object({
    type: z.literal('MISSING'),
    docType: z.enum(ZAugmentedDoc.types),
  }),
  { type: 'MISSING' }
)
export interface ZMissingDoc extends z.infer<typeof ZMissingDoc> {}

export const ZDocCryptId = ZAugmentedDoc.pick({ cryptId: true })
export interface ZDocCryptId extends z.infer<typeof ZDocCryptId> {}

export const ZAtlasSearchHighlight = z.object({
  path: z.string(),
  texts: z.object({ type: z.enum(['text', 'hit']), value: z.string() }).array(),
})

export interface ZAtlasSearchHighlight extends z.infer<typeof ZAtlasSearchHighlight> {}

export const ZAugmentedDocWithHighlights = ZAugmentedDoc.extend({
  highlights: ZAtlasSearchHighlight.array().optional(),
})

export interface ZAugmentedDocWithHighlights extends z.infer<typeof ZAugmentedDocWithHighlights> {}

const ZTypeFilter = Object.assign(ZDocType.array(), { type: 'type' as const })

export const ZDocFilters = z.object({
  title: ZStringFilter.optional(),
  type: ZTypeFilter.optional(),
  party: ZStringFilter.optional(),
  date: ZDateFilter.optional(),
})

export interface ZDocFilters extends z.infer<typeof ZDocFilters> {}

export const ZChatThreadInput = z.object({ query: z.string(), threadId: z.string().optional() })
export interface ZChatThreadInput extends z.infer<typeof ZChatThreadInput> {}

export const ZChatThreadOutput = z.object({
  messages: ZMessage.array(),
  docs: ZAugmentedDoc.array(),
  threadId: z.string(),
})

export interface ZChatThreadOutput extends z.infer<typeof ZChatThreadOutput> {}
