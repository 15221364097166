import {
  ActionIcon,
  Card,
  CloseButton,
  Group,
  HoverCard,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { IconInfoCircle, IconSparkles } from '@tabler/icons-react'
import React from 'react'
import { MessageForm } from '~/client/components/chat/form'
import { Messages } from '~/client/components/chat/messages'
import type { Message } from '~/client/components/chat/util'
import { hooks } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'

const Header: React.FC<{ closeChat: () => void }> = ({ closeChat }) => (
  <Group justify='space-between' p={0} m={0}>
    <Group gap='xs'>
      <ThemeIcon size='lg' ml='xs'>
        <IconSparkles size={36} />
      </ThemeIcon>
      <Title order={5}>Aerial AI Assistant</Title>
      <HoverCard width={500}>
        <HoverCard.Target>
          <ThemeIcon>
            <IconInfoCircle />
          </ThemeIcon>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Title order={5}>Disclaimer:</Title>
          <Text>
            The responses provided by this assistant are for informational purposes only and should
            not be considered legal advice. For professional legal assistance, consult your
            attorney.
          </Text>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
    <CloseButton m='xs' onClick={closeChat} />
  </Group>
)

const ChatWidget: React.FC<{ openChat: () => void }> = ({ openChat }) => (
  <ActionIcon
    size={60}
    radius='50%'
    variant='filled'
    onClick={openChat}
    style={{
      boxShadow: theme.shadows.xl,
    }}
  >
    <IconSparkles fill='white' size={31} />
  </ActionIcon>
)

export const AssistantChat: React.FC<{
  initialChatOpen?: boolean
  initialMessages?: Message[]
}> = ({ initialChatOpen = false, initialMessages = [] }) => {
  const { isLoading, data, mutateAsync } = hooks
    .trpc()
    .docs.addMessageToThread.useMutationWithCorp({ meta: { skipInvalidate: true } })
  const [messages, messagesHandlers] = useListState<Message>(initialMessages)
  const [chatOpen, setChatOpen] = React.useState(initialChatOpen)

  return !chatOpen ? (
    <ChatWidget openChat={() => setChatOpen(true)} />
  ) : (
    <Card w={500} shadow='xl' padding='lg' radius='md' withBorder>
      <Card.Section withBorder>
        <Header closeChat={() => setChatOpen(false)} />
      </Card.Section>

      <Card.Section h={450}>
        <Messages isLoading={isLoading} messages={messages} />
      </Card.Section>

      <Card.Section>
        <MessageForm
          isLoading={isLoading}
          appendMessages={messagesHandlers.append}
          submitMessage={mutateAsync}
          threadId={data?.threadId}
        />
      </Card.Section>
    </Card>
  )
}
