import type { ThemeIconProps } from '@mantine/core'
import { ThemeIcon } from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import React from 'react'
import { ActiveRedFlagIcon, DismissedRedFlagIcon } from '~/common/icons'

interface RedFlagStatusIconProps extends Omit<ThemeIconProps, 'children'> {
  state: 'inactive' | 'missing' | 'present' | 'dismissed'
}

export const RedFlagStatusIcon: React.FC<RedFlagStatusIconProps> = React.forwardRef<
  HTMLDivElement,
  RedFlagStatusIconProps
>(({ state, ...props }, ref) => {
  const iconStyle = { transform: 'scale(111%)' }
  switch (state) {
    case 'inactive':
      return (
        <ThemeIcon color='inactive' {...props} ref={ref}>
          <IconCircleCheck size='100%' style={iconStyle} />
        </ThemeIcon>
      )
    case 'present':
      return (
        <ThemeIcon color='go' {...props} ref={ref}>
          <IconCircleCheck size='100%' style={iconStyle} />
        </ThemeIcon>
      )
    case 'missing':
      return (
        <ThemeIcon color='urgent' {...props} ref={ref}>
          <ActiveRedFlagIcon size='100%' style={iconStyle} />
        </ThemeIcon>
      )
    case 'dismissed':
      return (
        <ThemeIcon color='go' {...props} ref={ref}>
          <DismissedRedFlagIcon size='100%' />
        </ThemeIcon>
      )
  }
})
