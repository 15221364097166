import type { PDFDocument } from '@cantoo/pdf-lib'
import {
  type UseMutationOptions,
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { useCorpCryptId } from '~/client/lib/hooks/corp'
import { useCreateDocAndUploadSingle } from '~/client/lib/hooks/upload'
import { loadPDFLibDocument } from '~/common/pdf-lib-util'
import type { ZDocType, ZLinkOptions } from '~/common/schema'
import { fetchRetry } from '~/common/util'

export interface ExtractOptions {
  firstPage: number
  lastPage?: number
}

interface MutationInputs extends ExtractOptions {
  title: string
  type: ZDocType
  linkOptions?: ZLinkOptions
}

interface MutationResults {
  createdUrl: string
}

export const extractPdfPages = async (
  source: PDFDocument,
  { firstPage, lastPage }: ExtractOptions
): Promise<Uint8Array> => {
  if (firstPage < 1 || (lastPage && lastPage < 1)) throw new Error('Page indexes should start on 1')
  const { PDFDocument } = await import('@cantoo/pdf-lib')
  const dst = await PDFDocument.create()

  // The array will contain a range of integers from 0 to pdfDoc.getPageCount() - 1
  const pageIndices = source.getPageIndices()
  // Transforming to 0-based indexing
  const pagesToExtract = pageIndices.slice(firstPage - 1, lastPage)

  // Invalid ranges will result in one empty page if we don't throw
  if (pagesToExtract.length === 0) throw new Error('Invalid page ranges')

  const pages = await dst.copyPages(source, pagesToExtract)

  // Even though the pages were copied (computationally expensive), we still need to add them
  // in the order we want (computationally cheap)
  pages.forEach((page) => dst.addPage(page))

  return dst.save({
    // Object streams are used to make the PDF file smaller (https://blog.idrsolutions.com/what-are-pdf-object-streams/)
    // They sometimes cause issues with pdfjs, so it's better not to use them here
    useObjectStreams: false,
  })
}

/**
 * Extracts the specified page from a document (1-based indexing) and upload it as a separate doc.
 * Note: The pdf can't be a mutation input because it's a circular structure
 */
export const useExtractAndUploadMutation = (
  pdf: PDFDocument | undefined,
  options?: UseMutationOptions<MutationResults, unknown, MutationInputs>
): UseMutationResult<MutationResults, unknown, MutationInputs> => {
  const { createDocAndUpload } = useCreateDocAndUploadSingle()
  const { corpCryptId, mkCurrentCorpRoute } = useCorpCryptId()

  return useMutation(async ({ firstPage, lastPage, title, type, linkOptions }) => {
    if (!pdf) throw new Error(`Can't extract without PDF`)
    const resultArray = await extractPdfPages(pdf, { firstPage, lastPage })
    const file = new File([resultArray], title)
    const { cryptId } = await createDocAndUpload({
      path: title,
      docInfo: {
        type,
        title,
        corpCryptId,
      },
      file,
      linkOptions,
    })
    return { createdUrl: mkCurrentCorpRoute('doc', cryptId.idStr) }
  }, options)
}

/**
 * Query to load the doc detail PDF with pdf-lib (dynamically imported)
 */
export const useLoadAndCheckPdfLibDocument = (): UseQueryResult<{
  pdf?: PDFDocument
  isValid: boolean
}> => {
  const pdfDataUrl = useDocDetailState((state) => state.pdfDataUrl)

  return useQuery(
    ['loadPdf', pdfDataUrl],
    async () => {
      if (!pdfDataUrl) throw new Error(`Can't load without PDF url`)
      // If pdfUrl is an ObjectUrl, this won't perform a network request, it'll just copy the stored bytes
      const pdfBytes = await fetchRetry(pdfDataUrl).then((res) => res.arrayBuffer())

      try {
        return {
          pdf: await loadPDFLibDocument(pdfBytes),
          isValid: true,
        }
      } catch (e) {
        return { isValid: false }
      }
    },
    {
      enabled: !!pdfDataUrl,
      retry: 0,
      keepPreviousData: true,
    }
  )
}
