import {
  ActionIcon,
  Group,
  Loader,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import { IconPencil, IconTool } from '@tabler/icons-react'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import Router from 'next/router'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import type { BaseRedFlagProps } from '~/client/components/red-flags/util'
import { useGetRedFlagPrimaryObjectUrl } from '~/client/components/red-flags/util'
import { nestedClickArea } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks } from '~/client/lib/hooks'
import { ActiveRedFlagIcon, DismissedRedFlagIcon } from '~/common/icons'
import type { Paginated } from '~/common/schema'
import type { ZAugmentedRedFlag } from '~/common/schema/red-flag'

const RedFlagItem: React.FC<{ flag: ZAugmentedRedFlag; onClick: () => void }> = ({
  flag,
  onClick,
}) => {
  const dismissMutation = hooks.trpc().redFlags.dismiss.useMutationWithCorp()
  const removeDismissalMutation = hooks.trpc().redFlags.removeDismissal.useMutationWithCorp()

  return (
    <Tooltip label={flag.display}>
      <Paper
        withBorder
        shadow='0'
        radius='md'
        p='sm'
        style={{ cursor: 'pointer' }}
        onClick={nestedClickArea.avoid(() => onClick())}
      >
        <Group gap='sm' wrap='nowrap'>
          {!flag.dismissed ? (
            <ThemeIcon color='urgent'>
              <ActiveRedFlagIcon size={30} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color='go'>
              <DismissedRedFlagIcon size={30} />
            </ThemeIcon>
          )}
          {/* TODO: use short names */}
          <Text flex={1}>{flag.display}</Text>

          <Stack gap={2}>
            {flag.type === 'CUSTOM' ? (
              <Tooltip label='Edit flag'>
                <ActionIcon variant='subtle' color='gray'>
                  <IconPencil size={20} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label='Fix'>
                <ActionIcon variant='subtle' color='gray'>
                  <IconTool size={20} />
                </ActionIcon>
              </Tooltip>
            )}

            {!flag.dismissed ? (
              <Tooltip label='Dismiss'>
                <ActionIcon
                  variant='subtle'
                  color='gray'
                  className={nestedClickArea.cssClass}
                  loading={dismissMutation.isLoading}
                >
                  <DismissedRedFlagIcon size={20} onClick={() => dismissMutation.mutate(flag)} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label='Re-flag'>
                <ActionIcon
                  variant='subtle'
                  color='gray'
                  className={nestedClickArea.cssClass}
                  loading={removeDismissalMutation.isLoading}
                >
                  <ActiveRedFlagIcon
                    size={20}
                    onClick={() => removeDismissalMutation.mutate(flag)}
                  />
                </ActionIcon>
              </Tooltip>
            )}
          </Stack>
        </Group>
      </Paper>
    </Tooltip>
  )
}

const CARD_HEIGHT = 450

interface RedFlagStackProps extends BaseRedFlagProps {
  queryResult: UseInfiniteQueryResult<Paginated<ZAugmentedRedFlag>>
}

export const RedFlagStack: React.FC<RedFlagStackProps> = ({
  queryResult,
  openCustomRedFlagModal,
}) => {
  const { data, hasNextPage, fetchNextPage } = queryResult
  const flags: ZAugmentedRedFlag[] = data?.pages.flatMap((p) => p.data) ?? []
  const getRedFlagPrimaryObjectUrl = useGetRedFlagPrimaryObjectUrl()
  const scrollRef = React.useRef<HTMLDivElement | null>(null)

  if (data && flags.length === 0) {
    return (
      <Text ta='center' my='xl' fw={500} size='lg'>
        No Red Flags! 🎉
      </Text>
    )
  }

  return (
    <ScrollArea.Autosize mah={CARD_HEIGHT} type='auto' viewportRef={scrollRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage}
        loader={
          <Group justify='center' py='md'>
            <Loader size='xs' />
            <Text size='xs' c='dimmed'>
              Loading
            </Text>
          </Group>
        }
        threshold={100}
        useWindow={false}
        getScrollParent={() => scrollRef.current}
      >
        <Stack gap='sm'>
          <LoadingErrorComp
            queryResult={queryResult}
            skeleton={
              <>
                {Array.from({ length: 6 }).map((_, i) => (
                  <Skeleton key={i} radius='md' height={80} />
                ))}
              </>
            }
          >
            {flags.map((flag) => (
              <RedFlagItem
                flag={flag}
                key={flag.cryptId.idStr}
                onClick={() => {
                  if (flag.type === 'CUSTOM') {
                    return openCustomRedFlagModal({ mode: 'edit', redFlag: flag })
                  }

                  const url = getRedFlagPrimaryObjectUrl(flag)
                  if (url) return Router.push(url)
                }}
              />
            ))}
          </LoadingErrorComp>
        </Stack>
      </InfiniteScroll>
    </ScrollArea.Autosize>
  )
}
